
.box {

  hr {
    margin: 2rem -1rem;
  }

  &:not(.no--padding) {
    padding: 3rem 2.4rem;
  }

  h1, h2, h3, h4, h5, h6 {
    margin-top: 0 !important;
  }

  p:last-of-type {
    margin-bottom: 0;
  }

  .button {
    margin-bottom: 0;
    width: 100%;
  }

  &--dark {
    background: $gray-dark;

    &, a, h1, h2, h3, h4, h5, h6 {
      color: #FFF !important;
    }
  }

  &--white {
    background: #FFF;
  }

}
