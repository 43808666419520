img{
	max-width: 100%;
	height: auto;
}

/*
article{
	img{
		&.alignleft{
			@extend .float-left;
		}

		&.alignright{
			@extend .float-right;
		}

		&.aligncenter{
			@extend .float-center;
		}

		&.alignleft,
		&.float-left{
			margin-right: 30px;
		}

		&.alignright,
		&.float-right{
			margin-left: 30px;
		}
	}	
}
*/

.gallery {
	@include grid-row();
	@include grid-row-nest();
	@extend .align-center;
	
	.gallery__item {
		@include grid-column(6);
		@include grid-column-end;
		margin-top: 15px;
		margin-bottom: 15px;

		img {
			margin-bottom: 0;
			max-width: auto;
			width: auto;
		}

		@include breakpoint(medium) {
			@include grid-column(4);
			@include grid-column-end;
		}

		a {
			display: block;
			background-color: #FFF;
			padding: 0px;
			border: 1px solid #cccccc;
			text-align: center;
			transition: border-color 200ms;

			&:hover, &:active, &.current {
				border-color: $brand;
			}
		}

	}

}

.img-person {
	max-width: 230px;
    float: right;
    margin-top: 60px;
    margin-left: 40px;
    margin-bottom: 20px; 

	@include breakpoint(large) {
    	margin-right: -160px;
	}

}
