
/*********** TESTATA *********/

header {
	position: relative;
	z-index: 1210;
}

.header-minimal {
	@include spacing-responsive(padding-top, 1.5);
	@include spacing-responsive(padding-bottom, 0.5);

	h1 {
		@include title-heavy;
		margin-bottom: 0 !important;
	}
}

.header__logo-cont {
	position: fixed;
	@extend .top-limit;
	@extend .left-limit;
	margin-left: -20px;
	z-index: 100;

	.logo {
		width: 160px;
		display: inline-block;

		svg {
			width: 100%;
		}

		@include breakpoint(medium) {
			width: 210px;
		}
	}

	.logotipo {
		@include hide-for-only(small);
		width: 277px;
		height: 35px;
		margin-top: 0px;
		margin-left: 10px;
		display: inline-block;
		color: $gray-light-medium;
		font-size: 14px;
		line-height: 1.4em;
		text-transform: uppercase;
		/*
		background: url('../../images/logo-tipo@2x.png') left 4px no-repeat;
		background-size: 100% auto;
*/
		@include breakpoint( large ) {
			margin-left: 20px;
		}

	}

}

.header__path {
	@include hide-for-only(small);
	@include hide-for-only(medium);
	position: fixed;
	@extend .top-limit;
	left: 50%;
	margin-top: 10px;
	transform: translateX(-50%);
	width: 126px;
	height: 20px;
	background: url('../../images/path-header@2x.png') no-repeat;
	background-size: 100% auto;
	z-index: 100;
}

.header__actions {
	position: fixed;
	@extend .top-limit;
	right: 70px;
	margin-top: 5px;
	z-index: 100;

	.header__actions-reserved {
		font-size: rem-calc(12);
		letter-spacing: 1px;
		color: $gray-dark;
		display: inline-block;

		i {
			display: inline-block;
			vertical-align: middle;
			width: 20px;
			height: 28px;

			line, path, circle {
				fill:none;
				stroke: #25282c;
				stroke-linejoin: round;
				stroke-width: 1.68px;
				transition: all 200ms;
			}

			@include breakpoint( medium ) {
				margin-right: 10px;
			}
		}


		&:hover, &:active {
			color: $brand;

			i {
				line, path, circle {
					stroke: $brand;
				}
			}
		}
	}

	@include breakpoint( medium ) {
		right: 100px;
	}

}

.header__lng {
	position: fixed;
	@extend .top-limit;
	right: 80px;
	margin-top: 10px;
	font-size: 12px;
	@extend .font-bold;
	letter-spacing: 2px;
	z-index: 100;

	a {
		color: $body-font-color;
		transition: all 200ms $transition-ease;

		&:hover, &:active, &.active {
			color: $brand;
		}
	}

	@include breakpoint( medium ) {
		right: 130px;
	}

}

.menu-handler {
	position: fixed;
	@extend .top-limit;
	@extend .right-limit;
	margin-right: -15px;
	width: 30px;
	height: 38px;
	z-index: 1400;
	cursor: hand;
	cursor: pointer;

	.line {
		position: absolute;
		left: 50%;
		margin-left: -9px;
		height: 3px;
		width: 18px;
		transition: all 200ms $transition-ease;
		background-color: $brand;
	}

	.line--1 {
		top: 0;
	}

	.line--2 {
		top: 17px
	}

	.line--3 {
		bottom: 0;
	}

	&:hover, &:active {

		.line--1 {
			top: 2px;
		}

		.line--3 {
			bottom: 2px;
		}

	}

}

.menuIsOpenComplete {

	.logo {
		svg {
			path,
			rect,
			polygon {
				fill: #FFF;
			}
		}
	}

	.header__actions-reserved {
		&:not(:hover):not(:active) {
			color: #FFF;

			i {
				line, path, circle {
					stroke: #FFF;
				}
			}
		}
	}
}

.menuIsOpen {

	.menu-handler {

		.line--1 {
			transform: rotate(45deg);
			transform-origin: center center;
			left: 13px;
			top: 11px;
			width: 8px;
		}

		.line--2 {
			width: 26px;
			margin-left: -13px;
			transform: rotate(-45deg);
			transform-origin: center center;
		}

		.line--3 {
			transform: rotate(45deg);
			transform-origin: center center;
			bottom: 11px;
			left: 27px;
			width: 8px;
		}
	}
}

.socials-cont {
	position: fixed;
	top: 120px;
	@extend .right-limit;
	transform: translateX(100%) rotate(90deg);
	transform-origin: left center;
	color: $body-font-color;
	text-transform: uppercase;
	font-size: 10px;
	opacity: 1;
	visibility: visible;
	@extend .font-bold;
	letter-spacing: 2px;
	z-index: 100;

	a {
		color: $body-font-color;
		display: inline-block;
		margin-right: 10px;
		transition: all 200ms $transition-ease;

		&:hover, &:active {
			color: $brand;
		}
	}
}

.header__bg {
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	height: 80px;
	background-color: $body-background;
	z-index: 99;
	opacity: 0;

	@include breakpoint( large ) {
		height: 115px;
	}

}

.header__logo-cont .logotipo,
.header__path,
.header__lng,
.header__actions,
.socials-cont,
.header__bg {
	transition: all 200ms;
}

.topCheckpoint {

	.header__logo-cont .logotipo,
	.header__path,
	.socials-cont {
	//.header__lng,
	//.header__actions {
		opacity: 0;
	}

	.socials-cont {
		opacity: 0;
		visibility: hidden;
	}

}

.topCheckpoint-1:not(.menuIsOpenComplete) {

	.header__bg {
		opacity: 0.7;
	}

}
