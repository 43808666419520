
@font-face {
    font-family: 'kayak_sansbold_italic';
    src: url('../../fonts/kayak_sans_bold_italic-webfont.woff2') format('woff2'),
         url('../../fonts/kayak_sans_bold_italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'kayak_sansbold';
    src: url('../../fonts/kayak_sans_bold-webfont.woff2') format('woff2'),
         url('../../fonts/kayak_sans_bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'kayak_sansregular_italic';
    src: url('../../fonts/kayak_sans_regular_italic-webfont.woff2') format('woff2'),
         url('../../fonts/kayak_sans_regular_italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'kayak_sansregular';
    src: url('../../fonts/kayak_sans_regular-webfont.woff2') format('woff2'),
         url('../../fonts/kayak_sans_regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

//$global-width: 68rem;
$global-width: 75rem;

/*
	Colors
*/
$gray-light-medium: #a3a8ad;
$gray-lighter: #fcfcfc; // Bg grigio principale
$gray-light: #e6e6e6; // Bg grigio principale
$gray-dark: #545e67; // Bg grigio principale Scuro
$gray-medium: #7f868d; // Colore del testo
$gray-card-bg: #d8d9db;

$white: #FFF; // Bianco easy

$body-font-color: #25282c;
$text-color: $body-font-color;
$body-background: $gray-light;
$header-color: $gray-dark;

$foundation-palette: (
  primary: #e20613,
  secondary: #767676,
  success: #3adb76,
  warning: #ffae00,
  alert: #cc4b37,
);

$brand: #e20613;

$black-dark: #161616;
$black: #333333;
$gray-bg: #fafafa;
//$gray-bg: red;
//$gray_light: #CCCCCC;
$green: #009999;
$red: #FF0000;

$link-hover-color: $brand;
$anchor-color: $text-color;

/*
 *  Buttons
*/
$button-radius: 30px;

/*
	Fonts
*/
$body-font-family: 'kayak_sansregular', sans-serif;
$font-bold: 'kayak_sansbold', sans-serif;
$font2: $body-font-family;
$font-icons: 'FontAwesome';
$fa-font-path: '../../fonts/';

$font-size-small: 13px;


/*
	Variables
*/
$border-radius: 5px;
$grid-gutter: 30px;
$transitions-default-time: 250ms;
$transition-ease: ease-out;
$transitions-default: all $transitions-default-time cubic-bezier(0.860, 0.000, 0.070, 1.000); /* easeInOutQuint */



/*
	Lightgallery
*/
$backdrop-opacity: 0.85;
$lg-icon-bg: rgba(0, 0, 0, 0.45);
$lg-icon-color: $white;
$lg-sub-html-bg: rgba(0, 0, 0, 0.45);
$lg-sub-html-color: #EEE;
$lg-border-radius-base: $border-radius;
$lg-path-fonts: '/fonts/';
$lg-path-images: '/images/';




$spacing: 90px;
$spacing-medium: 100px;
$spacing-large: 140px;
$spacing-xlarge: 140px;

$section-spacing: (
  small: 90px,
  medium: 100px,
  large: 140px,
  xlarge: 140px,
  xxlarge: 140px
);


$x-limit: 35px;
$y-limit: 20px;
$x-limit-large: 40px;
$y-limit-large: 40px;
$x-limit-xlarge: 50px;
$x-limit-xxlarge: 50px;



$zindex-outer: 10050;
$zindex-progressbar: 10083;
$zindex-controls: 10080;
$zindex-toolbar: 10082;
$zindex-subhtml: 10080;
$zindex-thumbnail: 10080;
$zindex-pager: 10080;
$zindex-playbutton: 10080;
$zindex-item: 10060;
$zindex-backdrop: 10040;


$blockquote-padding: 0;
$blockquote-border: 0px;
$blockquote-color: $gray-dark;



$fieldset-border: none;
$fieldset-padding: rem-calc(20);
$fieldset-margin: rem-calc(18 0);
$legend-padding: rem-calc(0 3);
$form-spacing: 1.15rem;
$helptext-color: $black;
$helptext-font-size: rem-calc(13);
$helptext-font-style: italic;

$select-background: $gray-dark;
$input-background: #EEEFF0;
$input-background-focus: lighten(#EEEFF0, 1%);
$input-border: none;
$input-border-focus: none;
$input-padding: $form-spacing;
$input-placeholder-color: #CCCFD1;
$input-line-height: 1rem;

/*
$input-prefix-color: $black;
$input-prefix-background: none;
$input-prefix-border: 1px solid $gray-light-medium;
$input-prefix-padding: 1rem;
$form-label-color: $black;
$form-label-font-size: rem-calc(14);
$form-label-font-weight: bold;
$form-label-line-height: 1.8;
$select-background: $white;
$select-triangle-color: $gray-dark;
$select-radius: $global-radius;
$input-color: $black;
$input-placeholder-color: $medium-gray;
$input-font-family: inherit;
$input-font-size: rem-calc(16);
$input-font-weight: $global-weight-normal;
$input-line-height: $global-lineheight;
$input-background: $white;
$input-background-focus: $white;
$input-background-disabled: $light-gray;
$input-border: 1px solid $medium-gray;
$input-border-focus: 1px solid $dark-gray;
$input-padding: $form-spacing / 2;
$input-shadow: inset 0 1px 2px rgba($black, 0.1);
$input-shadow-focus: 0 0 5px $medium-gray;
$input-cursor-disabled: not-allowed;
$input-transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
$input-number-spinners: true;
$input-radius: $global-radius;
$form-button-radius: $global-radius;
*/
