
.template-page--sectors {

	.side-right {
		h2, h3 {
			color: $brand;
			margin-top: 0;
		}

		h2 {
			font-size: 30px;
		}

	}

	.side-block {
		margin-bottom: 10px;

		.side-block__inner {
			padding: 40px 80px 50px 20px;
		}

	}

	.side-block--dark {
		background-color: $body-font-color;
		color: #FFF;

		.side-block__inner {
			padding-right: 20px;
		}

		svg {
			margin-bottom: 20px;
			max-width: 100px;

			circle, line, path {
				stroke: $gray-dark !important;
			}

		}

	}


	.side-block--light {
		background-color: #FFF;

		.side-block__inner {
			position: relative;
			min-height: 180px;
		}

		.link-button {
			position: absolute;
			width: 60px;
			height: 100%;
			right: 0;
			top: 0;
			background-color: #e6e6e6;
			transition: all 200ms;

			.arrow {
				position: absolute;
				top: 50%;
				left: 50%;
				width: 13px;
				height: 23px;
				margin-top: -12px;
				margin-left: -6px;
				background: url('../../images/arrow-right-b@2x.png') center center no-repeat;
				background-size: 100% auto;
				transition: all 200ms;
			}

		}

		a {
			color: $body-font-color;

			&:hover, &:active {
				.link-button {
					background-color: $gray-dark;

					.arrow {
						background-image: url('../../images/arrow-right-w@2x.png');
					}

				}
			}
		}

	}

	@include breakpoint(large) {

		.side-right {
			.go-back {
				left: -60px;
			}
		}

		.side-block {

			.side-block__inner {
				padding: 40px 120px 50px 30px;
			}
		}

		.side-block--dark {

			.side-block__inner {
				padding-right: 120px;
			}
		}

		.side-block--light {

			.link-button {
				width: 100px;
			}
		}

	}

}
