
.aboutus-ournumbers {
  margin-bottom: 4rem;

  &__item--2 {
    position: relative;
    padding-left: 10%;

    &:before {
      position: absolute;
      left: 5%;
      content: "";
      display: block;
      height: 100%;
      border-left: 1px solid $body-font-color;
      transform: rotate(25deg);
    }
  }

  &__item-row {
    margin-bottom: 2.4rem;
  }

  &__number,
  &__number-title {
    color: $brand;
    margin-bottom: 0;
  }

  &__number {
    font-size: 90px;
    font-size: 22vw;
    font-weight: bold;
    line-height: 1em;
    margin-right: 10px;

    @include breakpoint(medium) {
      font-size: 20vw;
    }

    @include breakpoint(large) {
      font-size: 110px;
    }
  }

  &__number-title {
    text-transform: capitalize !important;
  }
}
