

/*
	Forms style
*/
form {
	.button {
		width: 100%;
	}

	.column--pt {
		margin-top: 3rem;
	}

	.column {
		margin-bottom: $form-spacing;
}

	select {
		color: $input-placeholder-color;
		padding-top: 0;
		padding-bottom: 0;

		&:focus {
			background-color: $select-background;
		}
	}

	.acceptance_wrap {
	}

	&.unaccepted {
		.privacy {
			label {
				color: get-color(alert);
			}
		}
	}


	[type='password'] {
  	font-family: Arial, Helvetica, sans-serif;
	}
}


[type='text'],
[type='email'],
[type='number'],
[type='tel'],
[type='password'],
[type='date'],
[type='datetime'],
[type='datetime-local'],
[type='month'],
[type='week'],
[type='search'],
[type='time'],
[type='url'],
[type='color'],
textarea{
	padding-top: 30px;
}

#wpcf7-f1265-o1 {
	form {
		max-width: 900px;
	}
}


.wpcf7-response-output {
	@include callout();

	&.wpcf7-mail-sent-ok {
		@include callout(get-color(success));
	}

	&.wpcf7-acceptance-missing {
		@include callout(get-color(alert));
	}

	&.wpcf7-validation-errors {
		@include callout(get-color(alert));
	}
}

.wpcf7-form-control-wrap {

	.wpcf7-not-valid-tip {
		display: none !important;
	}

	&:not(.privacy):not(.newsletter) {

		.wpcf7-checkbox {
			@include flex-grid-row();

			.wpcf7-list-item {
				@include flex-grid-column(6);

				@include breakpoint(medium) {
					@include flex-grid-column(3);
				}
			}
		}
	}

	input, select, textarea {
		margin-bottom: 0;

		&.wpcf7-not-valid {
			@include form-input-error;
		}
	}

	span.wpcf7-not-valid {
		label {
			color: get-color(alert);
		}
	}

	label {
		line-height: 1.6rem;

		&, a {
			color: $gray-medium;
		}
	}
}

.wpcf7-checkbox,
.wpcf7-form-control-wrap {
	margin-top: $form-spacing / 2;

	.wpcf7-list-item {
		margin-left: 0;
		padding-left: 0 !important;
	}

	label {
	  display: block;
	  position: relative;
	  padding-left: 30px;
	  margin-bottom: 12px;
	  cursor: pointer;
	  user-select: none;

		/* Create a custom checkbox */
		&:after {
			position: absolute;
			content: "";
		  left: 7px;
		  top: 6px;
		  width: 5px;
		  height: 10px;
		  border: solid $gray-light-medium;
		  border-width: 0 3px 3px 0;
		  transform: rotate(45deg);
		}

		/* Show the checkmark when checked */
		input:checked ~ &:after {
		  display: block;
		}

		input {
		  position: absolute;
		  cursor: pointer;
		  height: 20px;
		  width: 20px;
			left: 0;
			top: 2px;
			background: $gray-light-medium;
    	appearance: none;
			outline: none;
			transition: background 200ms;

			&:checked {
				background: $gray-dark;
			}
		}
	}
}

form .step-form button[data-slide-to] {
	@include show-for(medium);
}

#step-form-nav {
	position: relative;

	&:after {
		position: absolute;
		content: "";
		top: 20px;
		left: 10%;
		right: 10%;
		display: block;
		z-index: -1;
		border-top: 1px solid $gray-light-medium;
		opacity: 0.6;
	}

	.column {
		text-align: center;
		min-width: 110px;
		margin-bottom: 0;
	}

	.dot {
		border-radius: 50%;
		width: 40px;
		height: 40px;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 5px;
		background: $gray-dark;
		color: #FFF;
		line-height: 43px;
		text-align: center;
		transition: background-color 200ms;
	}

	.title {
		font-size: 14px;
		color: $gray-light-medium;
		transition: color 200ms;
	}

	.column.active {
		.dot {
			background-color: $brand;
		}

		.title {
			color: $body-font-color;
		}
	}

}
