

.template-page--solutions,
.template-page--products,
.template-page--sectors {

	.arrow-right {
		position: absolute;
		top: 50%;
		right: 20px;
		width: 9px;
		height: 16px;
		transform: translateY(-50%);

		&:after, &:before {
			position: absolute;
			content: "";
			height: 3px;
			width: 12px;
			background-color: #a9aeb3;
		}

		&:before {
			transform: rotate(-45deg);
			margin-top: 11px;
		}

		&:after {
			transform: rotate(45deg);
			margin-top: 4px;
		}

	}

	.page-content {

		.side-left {
			@include grid-column(12);

			@include breakpoint(medium) {
				@include grid-column(5);
				@include grid-column-end;
			}

			@include breakpoint(large) {
				@include grid-column(4.5);
				@include grid-column-end;
			}

			.go-back {
				@include show-for-only(small);
			}

			h2 {
				@extend .title--small;
				margin-bottom: 2.4em;
			}

			p {
				color: $brand;
				font-size: 20px;
			}

		}

		.side-right {
			@include grid-column(12);

			@include breakpoint(medium) {
				@include grid-column(7);
				@include grid-column-end;
			}

			@include breakpoint(large) {
				@include grid-column(6);
				@include grid-column-end;
				@include grid-column-position(1.5);
			}

			.go-back {
				@include hide-for-only(small);
			}

		}

		.side-right__inner-spacing {
			padding: 20px 0px 20px 0px;

			@include breakpoint(medium) {
				padding: 30px 0px 20px 86px;
				max-width: 440px;
			}
		}

	}

	.related-references {
		h2 {
			@extend .title--small;
			margin-bottom: 2.4em;
		}
	}



	.related-cont {
		.side-right--large {
			padding-top: 50px;
			@include grid-column(12);

			@include breakpoint(medium) {
				@include grid-column(7);
				@include grid-column-end;
			}

			@include breakpoint(large) {
				@include grid-column(6.5);
				@include grid-column-end;
				@include grid-column-position(1);
			}

			.go-back {
				position: relative;
				top: 0;
				margin-top: -20px;
				margin-bottom: 20px;
			}


			.side-right__inner-spacing {
				padding: 0px 0px 20px 0px;

				@include breakpoint(medium) {
					padding: 0px 0px 20px 0px;
					max-width: 1000px;
				}
			}

			.side-right__wrapper {
			}

			.side-right__inner-bg {
				right: 0;
				left: 0;
			}

			.content {
				padding-left: 60px;
				padding-right: 60px;
				padding-bottom: 20px;
			}

			.thumb-cont {
				margin-top: 0;
				margin-left: -60px;
				margin-right: -60px;
				box-shadow: none;
				transform: translate(0);
			}

			@include breakpoint(medium down) {

				.side-right__inner-content {
					padding: 0 !important;
				}

				.content {
					padding-left: 40px;
					padding-right: 40px;
				}

				.thumb-cont {
					margin-left: -40px;
					margin-right: -40px;
				}

			}


			@include breakpoint(small down) {


				.content {
					padding-left: 20px;
					padding-right: 20px;
				}

				.thumb-cont {
					margin-left: -20px;
					margin-right: -20px;
				}

			}

		}

	}



}


body,
.header-page .header-page__overlay-outher,
.header-page h1,
.header-page .header-page__intro,
.header__logo-cont .logo,
.header__logo-cont .logotipo,
.solutions-slider__nav .item,
.header__bg {
	transition: all 400ms;
}


.template-page--dark {
	background-color: $gray-dark;

	.button--icon {
		color: #FFF;

		&:hover, &:active {
			color: $brand;
		}

	}

	.more-info-cont {
		a {
			color: #FFF;

			&:hover, &:active {
				color: $brand !important;
			}
		}
	}

	.header__bg {
		background-color: #4A545D;
	}

	.header__actions {

		i.icon-reserved {

			line, path, circle {
				stroke: #FFF;
			}

			&:hover, &:active {
				line, path, circle {
					stroke: $brand;
				}
			}
		}
	}

	.section__home-intro .quad p,
	.section__home-intro .header-page .header-page__overlay-outher p,
	.header-page .section__home-intro .header-page__overlay-outher p,
	.section__home-intro .solutions-slider__item .header-page__overlay-outher p,
	.solutions-slider__item .section__home-intro .header-page__overlay-outher p {
		color: #FFF;
	}

	.header-page {

		h1 {
			color: #FFF;
		}

		.header-page__overlay-outher {
			background-color: $gray-dark;
		}

		.header-page__intro {
			color: #FFF;
		}

	}

	.header__logo-cont {

		.logo {
			//background-image: url('../../images/logo-w@2x.png');
		}

		.logotipo {
			//background-image: url('../../images/logo-tipo-w@2x.png');
			color: #FFF;
		}

	}

	.header__path {
		background-image: url('../../images/path-header-w@2x.png');
	}

	.header__lng,
	.socials-cont {

		&, a {
			color: #868d93;
		}

		a:hover, a:active, a.active {
			color: $brand;
		}

	}

	.go-back {

		.icon {
			border-color: #FFF;
		}

		a {
			color: #FFF;

			&:hover, &:active {
				color: $brand;
			}

		}


	}


	.page-content {
		color: #FFF;

		h2, h3 {
			color: #FFF;
		}

		h4 {
			color: #FFF;
		}

		h6 {
			color: #FFF;
		}

		.page-content__spacer {
			background-color: $gray-dark;
			@extend .shadow--3;
		}

	}


	.related-cont {

		.side-left h2 {
			color: #FFF;
		}

		.related__nav {

			a {
				color: #FFF;
				border-bottom: 1px solid lighten( $gray-dark, 5%);
				background-color: $gray-dark;
			}

			a:hover, a:active,
			.current-menu-item a {
				color: #FFF;
				background-color: $brand;

				.arrow-right {
					&:before, &:after {
						background-color: #fff;
					}
				}

			}

			circle, line, path, polygon, rect, polyline {
				stroke: #FFF !important;
			}

		}



		.side-right__inner-bg {
			background-color: $gray-dark;
			@extend .shadow--2;
		}

		.side-right {
			.thumb-cont {
				@extend .shadow--3;
			}
		}

		.more-info-cont a {
			&:hover, &:active {
				color: #FFF;
			}
		}

	}

	.related-references {
		h2 {
			color: #FFF;
		}
	}

	.related-references-slide .item {
		@extend .shadow--1;
	}

	.social-share {

		div {
			color: #9ca2a7;
		}

		a {
			color: #FFF;

			&.social-mail { border-color: #FFF; }
		}

	}

	.news-box {
		@extend .shadow--1;

		.path {
			background-image: url('../../images/path-header@2x.png');
		}
	}


	.home-slider__header {
		h3 {
			color: #FFF;
		}
	}


	.solutions-slider__nav {

		.item {
			color: #FFF;
		}

	}


	.blocks {
		.blocks__block--semidark .blocks__block-inner-wrap {
			//@extend .shadow--1;
			background-color: #656e76;
		}
	}


	footer {
		color: #868d93;
	}

}
