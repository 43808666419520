
.button {
	text-decoration: none !important;
	font-weight: 700;
	letter-spacing: 0.12rem;
	font-size: 15px;

	&.no-mt {
		margin-top: 0;
	}

	&.no-mb {
		margin-bottom: 0;
	}

	&.large {
		padding: 10px 20px;
		font-size: 15px;
		text-transform: uppercase;
		font-weight: 700;
	}

	&--white {
		background-color: #FFF;
		color: $body-font-color;
	}

	&--gray {
		background-color: $gray-dark;
		color: #FFF;
	}
}

.button--icon {
	background-color: transparent !important;
	color: $gray-dark;
	padding: 0px;
	font-size: 16px;
	margin: 0;

	.icon {
		display: block;
		width: 30px;
		height: 30px;
		float: left;
		border-radius: 50%;
		border: 1px solid $gray-dark;
		background-size: 15px auto;
	}

	.phrase {
		float: left;
		display: block;
		line-height: 34px;
		margin-left: 10px;
	}

	&:hover, &:active {
		color: $brand;
	}

	@include breakpoint(medium) {

		.icon {
			width: 40px;
			height: 40px;
		}

		.phrase {
			line-height: 40px;
		}

	}

	@include breakpoint(large) {
		letter-spacing: 0.1em;

		.icon {
			width: 55px;
			height: 55px;
		}

		.phrase {
			margin-left: 30px;
			line-height: 55px;
		}

	}

}

.button--icon-download {

	.icon {
		background: url('../../images/icon-download@2x.png') center center no-repeat;
		background-size: 12px auto;

		@include breakpoint(medium) {
			background-size: 15px auto;
		}

		@include breakpoint(large) {
			background-size: 23px auto;
		}

	}

}

.button--icon-goback {

	.icon {
		background: url('../../images/arrow-left@2x.png') 49% center no-repeat;
		background-size: 8px auto;

		@include breakpoint(medium) {
			background-size: 10px auto;
		}

		@include breakpoint(large) {
			background-size: 14px auto;
		}

	}
}

/*
	Basic filled button
*/
.button--default {
	@include button();
	padding-left: 25px;
	padding-right: 25px;
	border-radius: 20px;
	letter-spacing: 0.1em;

	@include breakpoint(small only) {
		@include button-expand();
	}
}


.select2-container {
    width: auto !important;

    &, * {
		outline: none !important;
    }

}

.select2-dropdown {
	background-color: darken($brand, 5%);
	border-color: darken($brand, 5%);
	color: #FFF;
}

.select2-container--default .select2-results__option[aria-selected=true] {
	background-color: darken($brand, 10%);

}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
	background-color: darken($brand, 15%);
	color: #FFF;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
	border-color: transparent transparent #FFF transparent;
}

.select2-search {
	display: none !important;
}

.select2-container--default .select2-selection--single {
    background-color: $brand;
    border: 1px solid $brand;
    border-radius: 20px;
    height: 40px;

    .select2-selection__placeholder {
    	color: #FFF;
    }

    .select2-selection__rendered {
	    color: #FFF;
	    line-height: 40px;
	    padding-left: 35px;
	    padding-right: 45px;
	}

	.select2-selection__arrow {
		height: 40px;
		width: 40px;

		b {
		    border-color: #FFF transparent transparent transparent;
		}

	}

}
