

.blocks {
	@include flex-grid-row(nest);
	@extend .section-spacing-b;
}

.card-fiore {
	@include flex-grid-column(12);
	margin-top: 2rem;
	letter-spacing: 0.1em;

  a {
    text-decoration: none !important;
  }

	.date {
		color: $brand;

		&--gray {
 			color: $gray-dark;
		}
	}

	.title {
		color: #FFF;
		line-height: 1.2em;
    margin-bottom: 2rem;
	}

	p {
		color: #FFF;
    margin-bottom: 0;
	}

	.link-label {
		color: #FFF;
		text-transform: uppercase;
		font-size: 13px;
		letter-spacing: 0.1em;
		font-weight: 300;

		&--red {
			color: $brand;
		}
	}

	.card-fiore__img-cont {
		position: relative;
		padding-bottom: 60%;
		overflow: hidden;

		img {
			width: 100%;
			height: 100%;
			position: absolute;
			object-fit: cover;
			object-position: center center;
      transition: all 400ms $transition-ease;
		}
	}

	.card-fiore-max-w {
		max-width: 380px;
	}

	&:not( .card-fiore--no-link ) {

		.card-fiore-inner-wrap:hover,
		.card-fiore-inner-wrap:active {
			@extend .shadow--2;

			.card-fiore__img-cont {
        img {
  				transform: scale(1.1);
        }
			}
		}

	}

	@include breakpoint(medium) {
		@include flex-grid-column(6);
	}

	@include breakpoint(large) {
		@include flex-grid-column(4);

		.title {
			font-size: 22px;
      line-height: 1.8rem;
		}
	}
}

.card-fiore--no-link {
	.link-label {
		display: none !important;
	}
}

.card-fiore--slide-panel {
	@include flex-grid-column(12);
	margin-top: 0px !important;
}

.card-fiore-inner {
	position: relative;
	display: block;
  height: 100%;
}

.card-fiore-inner-wrap {
	background-color: $gray-medium;
	position: relative;
	overflow: hidden;
  height: 100%;
	@extend .shadow;
	transition: box-shadow 400ms;

  > .column {
  }
}

.card-fiore__details {

  .column {
    padding-top: 7px;
    padding-bottom: 5px;
  }

  .column--1 {
    background: $brand;
    color: #FFF;
  }

  .column--2 {
    background: $gray-dark;
    color: #FFF;
  }
}

.card-fiore-spacer {
	margin: 1.5rem;

	@include breakpoint(large) {
		margin: 2rem;
	}

	@include breakpoint(xlarge) {
		margin: 2.5rem;
	}

}

.card-fiore__content {
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 5;

  .card-fiore-spacer {
    margin-bottom: 0;
  }
}

.card-fiore-overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: $gray-dark;
	opacity: 0.68;
	z-index: 2;
}

.card-fiore--red {

	.card-fiore-overlay {
		background-color: $brand;
	}

}

.card-fiore--light {

	.card-fiore-inner-wrap {
		background-color: $gray-card-bg;
	}

	.card-fiore-overlay {
		display: none;
	}

	.title {
		color: $body-font-color;
	}

	p {
		color: $gray-medium;
	}

}

.card-fiore--lighter {

	.card-fiore-inner-wrap {
		background-color: $gray-light;
	}

	.card-fiore-overlay {
		display: none;
	}

	.title {
		color: $body-font-color;
	}

	p {
		color: $gray-medium;
	}

}

.card-fiore--dark {

	.card-fiore-overlay {
		display: none;
	}

	.link-label {
		color: #FFF !important;
	}

	.title {
		color: #FFF !important;
	}

	p {
		color: $gray-medium !important;
	}

}

.card-fiore--semidark {

	.card-fiore__content {
		background-color: #656e76;
	}

	.card-fiore-overlay {
		display: none;
	}

	.title {
		color: #FFF;
	}

	p {
		color: $gray-light-medium;
	}

}

.card-fiore--link-right {

	.link-label {
		float: right;
		display: inline-block;
		color: $brand;
	}

}

.card-fiore--title-big {
	.title {
		font-size: 30px;
		color: $gray-dark;

		span {
			font-size: 30px;
		}
	}
}

.card-fiore--full {

	.title {
		text-transform: uppercase;
		font-size: 53px;
		font-size: 10vw;
		letter-spacing: 0.1em;
		line-height: 1em;
		margin-top: 0.8em;
		margin-bottom: 0.2em;
	}

	p {
		color: #FFF;
		font-weight: 700;
		font-size: 16px;
	}

	.link-label {
		position: absolute;
		bottom: 30px;
		left: 20px;
		margin-top: 0em;
	}

	.img-real-cont {
		height: 100%;
	}

	.card-fiore__content {
		top: 30px;
		background-color: transparent;
	}

	.card-fiore-max-w {
		max-width: 1000px;
	}

	@include breakpoint(medium) {

		.title {
			font-size: 40px;
		}

	}

	@include breakpoint(large) {

		.title {
			font-size: 53px;
		}

		p {
			font-size: 22px;
		}

		.link-label {
			left: 40px;
		}

	}

}

.card-fiore--no-shadow {

	.card-fiore-inner-wrap {
		box-shadow: none !important;
	}

}

.card-fiore.elem-to-show {

	.card-fiore-overlay-for-ani {
		position: absolute;
		top: 100%;
		left: 0;
		right: 0;
		bottom: 0;
		transition: all 400ms 200ms;
		background-color: darken($gray-light, 5%);
		z-index: 10;

		//animation: overlayopen 2s ease-in-out;
		//animation-play-state: paused;
	}

	&.card-fiore--dark {
		.card-fiore-overlay-for-ani {
			background-color: darken($gray-dark, 5%);
		}
	}

	.card-fiore-inner-wrap {
		opacity: 0;
	}


}


.card-fiore.elem-to-show.elem-to-show--show {

	.card-fiore-overlay-for-ani {
		top: 0;
		bottom: 100%;
		animation: overlayopen 2s ease-in-out running;
	}

	.card-fiore-inner-wrap {
		opacity: 1;
		animation: blockopen 2s ease-in-out running;
	}

}

@keyframes overlayopen {
	0% {
		top: 100%;
		bottom: 0;
	}
	20% {
		top: 0%;
		bottom: 0;
	}
	80% {
		top: 0%;
		bottom: 0;
	}
	100% {
		top: 0;
		bottom: 100%;
	}
}

@keyframes blockopen {
	0% {
		opacity: 0;
	}
	40% {
		opacity: 0;
	}
	80% {
		opacity: 1;
	}
	100% {
		opacity: 1;
	}
}
