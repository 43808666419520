
.img-responsive {
    display: block;
    max-width: 100%;
    height: auto;
}




.left-limit {
	left: $x-limit;

	//@include breakpoint(medium) { left: $x-limit; }
	@include breakpoint(large) { left: $x-limit-large; }
	@include breakpoint(xlarge) { left: $x-limit-xlarge; }
	@include breakpoint(xxlarge) { left: $x-limit-xxlarge; }
}

.right-limit {
	right: $x-limit;

	//@include breakpoint(medium) { right: $x-limit; }
	@include breakpoint(large) { right: $x-limit-large; }
	@include breakpoint(xlarge) { right: $x-limit-xlarge; }
	@include breakpoint(xxlarge) { right: $x-limit-xxlarge; }
}

.top-limit {
	top: $y-limit;

	//@include breakpoint(medium) { top: $y-limit; }
	@include breakpoint(large) { top: $y-limit-large; }
	//@include breakpoint(xlarge) { top: $y-limit-xlarge; }
}

.bottom-limit {
	bottom: $y-limit;

	//@include breakpoint(medium) { bottom: $y-limit; }
	@include breakpoint(large) { bottom: $y-limit-large; }
	//@include breakpoint(xlarge) { bottom: $y-limit-xlarge; }
}


.pright-limit {
	padding-right: $x-limit;

	//@include breakpoint(medium) { padding-right: $x-limit; }
	@include breakpoint(large) { padding-right: $x-limit-large; }
	@include breakpoint(xlarge) { padding-right: $x-limit-xlarge; }
	@include breakpoint(xxlarge) { padding-right: $x-limit-xxlarge; }
}

.pleft-limit {
	padding-left: $x-limit;

	//@include breakpoint(medium) { padding-left: $x-limit; }
	@include breakpoint(large) { padding-left: $x-limit-large; }
	@include breakpoint(xlarge) { padding-left: $x-limit-xlarge; }
	@include breakpoint(xxlarge) { padding-left: $x-limit-xxlarge; }
}

/*
$spacing-y: (
  small: 40px,
  medium: $spacing-medium,
  large: 100px,
  xlarge: 120px,
);
*/

@mixin spacing-responsive($property: margin-top, $factor: 1, $space-data: $section-spacing, $valOperator: '' ){
  @each $query, $space in $space-data {
    @include breakpoint($query){
      #{$property}: #{$valOperator}$space * $factor;
    }
  }
}

@mixin section-spacing-t($factor: 1) {
  @include spacing-responsive(padding-top, $factor);
}

@mixin section-spacing-b($factor: 1) {
  @include spacing-responsive(padding-bottom, $factor);
}

@mixin section-spacing($factor: 1) {
  @include spacing-responsive(padding-top, $factor);
  @include spacing-responsive(padding-bottom, $factor);
}

.section-spacing-t {
	@include section-spacing-t();
}

.section-spacing-b {
	@include section-spacing-b();
}

.section-spacing {
	@include section-spacing();
}



.section-spacing-neg-t {
	margin-top: -($spacing);

	@include breakpoint(medium) { margin-top: -($spacing-medium); }
	@include breakpoint(large) { margin-top: -($spacing-large); }
	@include breakpoint(xlarge) { margin-top: -($spacing-xlarge); }

}

.section-spacing-neg-b {
	margin-bottom: -($spacing);

	@include breakpoint(medium) { margin-bottom: -($spacing-medium); }
	@include breakpoint(large) { margin-bottom: -($spacing-large); }
	@include breakpoint(xlarge) { margin-bottom: -($spacing-xlarge); }

}

.section-spacing-neg {
	@extend .section-spacing-t;
	@extend .section-spacing-b;
}



.section-half-spacing-t {
	padding-top: ($spacing/2);

	@include breakpoint(medium) {	padding-top: ($spacing-medium/2); }
	@include breakpoint(large) {	padding-top: ($spacing-large/2); }
	@include breakpoint(xlarge) {	padding-top: ($spacing-xlarge/2); }

}

.section-half-spacing-b {
	padding-bottom: ($spacing/2);

	@include breakpoint(medium) {	padding-bottom: ($spacing-medium/2); }
	@include breakpoint(large) {	padding-bottom: ($spacing-large/2); }
	@include breakpoint(xlarge) {	padding-bottom: ($spacing-xlarge/2); }

}

.section-half-spacing {
	@extend .section-half-spacing-t;
	@extend .section-half-spacing-b;
}
