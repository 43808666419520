
.header-page {
	@extend .container;
	@extend .section-spacing-t;
	@extend .section-spacing-b;
	text-align: right;

	.side-full {
		position: relative;
	}

	.date {
		color: $brand;
		padding-bottom: 10px;
		letter-spacing: 0.1em;

		@include breakpoint(large) {
			padding-bottom: 20px;
		}

	}

	h1 {
		@include title-heavy();
	}

	h3 {
		position: relative;
		font-size: 14px;
		padding: 5px 0px;
		display: inline-block;
		text-transform: uppercase;
		letter-spacing: 0.1em;
		line-height: 0.8em;
		margin-left: $quad-left-space;
		color: $brand;

		&:after {
			content: "";
			width: ($quad-left-space)-10;
			height: 1px;
			background-color: $gray-dark;
			position: absolute;
			top: 50%;
			left: -($quad-left-space);
		}

		@include breakpoint(medium) {
			margin-bottom: 50px;
			margin-left: $quad-left-space-medium;

			&:after {
				width: ($quad-left-space-medium)-10;
				left: -($quad-left-space-medium);
			}

		}

		@include breakpoint(large) {
			margin-left: $quad-left-space-large;

			&:after {
				width: ($quad-left-space-large)-10;
				left: -($quad-left-space-large);
			}

		}

	}

	.header-page__details {
		margin-top: 20px;

		@include breakpoint(medium) {
			min-width: 450px;
		}
	}

	.headings-cont {
		position: absolute;
		left: 0;
		text-align: left;
		@include grid-column-gutter;
		top: 55%;
		transform: translateY(-50%);
		z-index: 5;
	}

	.header-page__img-wrapper {
		@extend .shadow;
	}

	.header-page__img-cont {
		position: relative;
		z-index: 1;
		width: 900px;
		max-width: 80%;
		display: inline-block;

		img {
			width: 100%;
		}

		.event-subscription-link {
			@include hide-for(medium down);
		}

	}

	.header-page__overlay-outher {
		position: absolute;
		@extend .quad;
		@extend .quad--over-1;
		top: 5%;
		//right: 45%;
		//width: 70%;
		right: 55%;
    	width: 60%;
		height: 90%;
		z-index: 5;
		background-color: $gray-light;
		opacity: 0.8;

		@include breakpoint(large) {
			right: 62%;
	    	width: 55%;
		}
	}


	.header-page__intro {
		@include hide-for-only( small );
		position: absolute;
		display: inline-block;
		width: 210px;
		//left: 100%;
		left: 50%;
		top: 45%;
		//top: 60%;
		//transform: translateY(-50%);

		@include breakpoint(large) {
			left: 55%;
			top: 50%;
		}

	}

	.header-page__intro--spacing {
	    letter-spacing: 3px;
	    line-height: 1.4em;
	}

	.headings-cont {
		.header-page__overlay-outher {
			display: none;
			width: 70%;
			right: auto;
			left: 15%;
			top: -15%;
			bottom: -15%;
			height: auto;
			z-index: -1;

			@include breakpoint(medium) {
				width: 98%;
				top: -20%;
				left: 20%;
				bottom: -0%;
			}

		}
	}

	.event-subscription-link {
		position: absolute;
		bottom: 40px;
		right: 40px;
		margin: 0;
	}


}

.template-page--references {

	.header-page--title-small h1 {
		min-height: auto !important;
		letter-spacing: 0.1em;
	}

	@include breakpoint(medium) {
		.headings-cont {
			min-width: 240px;
		}
	}


}


//.template-page--standard,
//.template-page--references {

.go-back--no {
	display: none !important;
}

.go-back--left {
	position: relative;
	margin-top: 20px;
	text-align: left;
	bottom: -20px;
	z-index: 10;

	@include breakpoint(medium) {
		margin-top: 0px;
		position: absolute;
		left: 0;
		@include grid-column-gutter;
	}

}


.header-page--title-small {
	@extend .section-half-spacing-b;

	h1 {
		text-transform: none;
		font-size: 24px;
		line-height: 1.2em;
		letter-spacing: 0em;
		margin-bottom: 0.6em;
		color: $gray-dark;
		max-width: 80%;
		min-height: 70px;

		@include breakpoint(medium) {
			font-size: 40px;
			max-width: 420px;
			min-height: 90px;
		}

		@include breakpoint(large) {
			min-height: 140px;
		}

	}

	.headings-cont {
		.header-page__overlay-outher {
			display: block;
		}
	}

	.header-page__img-cont {
		.header-page__overlay-outher {
			display: none;
		}
	}

}


.header-page--title-uppercase {
	h1 {
		text-transform: uppercase;
		letter-spacing: 0.1em;
	}
}

.header-page--title-white {
	h1 {
		color: #FFF;
	}
}


.header-page--subtitle-wrapped {

	h3 {
		background-color: $brand;
		color: #FFF !important;
		padding: 5px 20px;

		@include breakpoint(medium) {
			display: block;
		}

	}

}


.page-content {
	@extend .container;
	@extend .section-half-spacing-b;
	color: $gray-dark;

	p {
		line-height: 1.4em;
	}


	h2, h3 {
		font-size: 28px;
		color: $brand;
		margin-top: 2em;
	}

	h4 {
		font-size: 16px;
		color: $brand;
		margin-top: 2em;
	}

	h6 {
		display: inline-block;
		font-size: 16px;
		color: $brand;
	}

	.page-content__inner {
		@include grid-column(12);

		@include breakpoint(large) {
			@include grid-column(10.7);
			@include grid-column-position(1.3);
		}

	}

	&--light {
		.page-content__spacer {
			background-color: $gray-lighter;
		}
	}

	&--white {
		.page-content__spacer {
			background-color: #FFF;
		}
	}

	.page-content__spacer {
		padding: 30px 15px;

		.event-subscription-link {
			margin-top: 30px;
			margin-bottom: 30px;
		}

		@include breakpoint(medium) {
			padding: 50px 30px 50px 50px;
		}

	}

	.page-content__max-w {
		max-width: 610px;

		h1, h2, h3, h4, h5  {
			&:first-child {
				margin-top: 0;
				margin-bottom: $paragraph-margin-bottom+0.2;
			}
		}

	}

	.event-subscription-cont--before-content {
		text-align: center;
		@include hide-for(large);
	}

	.event-subscription-cont--after-content {

		@include breakpoint(large) {
			position: absolute;
			right: 40px;
			bottom: 30%;
		}

	}

}

.page-content--no-space-b {
	padding-bottom: 0;
}

.article-download {
	position: relative;
	margin-top: 30px;

	@include breakpoint(medium) {
		margin-top: 35px;
		float: left;
	}

	@include breakpoint(large) {
		margin-top: 30px;
	}

}

.social-share {
	position: relative;
	margin-top: 10px;

	div {
		display: none;
		position: relative;
		color: #9ca2a7;
		top: 0px;
		margin-right: 10px;
		line-height: 30px;
	}

	a {
		width: 30px;
		height: 30px;
		display: inline-block;
		border-radius: 50%;
		border: 1px solid #000;
		margin-left: 10px;
		line-height: 32px;
		color: #545e67;
		text-align: center;
		transition: all 200ms;

		&:first-of-type {
			margin-left: 0px;
		}

		&:hover, &:active {
			opacity: 0.8;
		}
	}

	@include breakpoint(medium) {
		margin-top: 4rem;
		letter-spacing: 0.1em;

		div {
			display: inline-block;
		}

	}

}
