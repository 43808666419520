.lg-actions{
	/*
	.lg-next{
		transition: $transitions-default;
		&:hover{
			transform: translateX(4px);
			-webkit-transform: translateX(4px);
		}
	}
	
	.lg-prev{
		transition: $transitions-default;
		&:hover{
			transform: translateX(-4px);
			-webkit-transform: translateX(-4px);
		}
	}
	*/

	.lg-next.disabled,
	.lg-prev.disabled{
		opacity: 0.1;
	}
}

.lg-outer{
	.lg-item{
		background: url(/images/lightgallery/loading.gif) no-repeat scroll center center transparent;
	}
}

.lg-sub-html{
	font-size: 12px;
}