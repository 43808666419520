
.page-home {
	
	.section__home-intro { 
		.quad {
			//transition: all 400ms;

			h1, h3, p {
				//transition: all 400ms;
			}

		}
	}

}

.fade-out,
.fade-in {
	
	.page-home {
		.section__home-intro {
			.quad {
				box-shadow: none !important;
				
				h1, h3, p {
					opacity: 0;
				}

			}
		}
	}

}