
.content-intro {

  .column {
    margin-bottom: 2rem;
  }

  .column--2 {
    @include breakpoint(medium) {
      text-align: center;
    }
  }

  br {
    display: none;
  }

  h1, h2, h3, h4 {
    margin-bottom: 0;
    color: $body-font-color !important;

    &.text--red {
    	color: $brand !important;
    }
  }
}

.content-and-side-right {

  .column {
    margin-bottom: 1.875rem;
  }

  .box {
    //max-width: 300px;
  }
}


.template-page--events {

  .content-and-side-right {
    .box {
      color: $gray-light-medium !important;

      h1, h2, h3, h4, h5, h6 {
        margin-bottom: 1.2rem;
      }

      strong, a, h1, h2, h3, h4, h5 {
        color: #FFF;
      }
    }
  }

}

.content-contacts {

  &__block {
    @include breakpoint(medium down) {
      margin-bottom: 3rem;
    }
  }

  &__item {
    @include breakpoint(medium) {
      //min-height: 300px;
    }
  }
}
