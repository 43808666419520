

.related-references {
	@extend .container;

}


.depliant-cont {
	display: inline-block;
	margin-top: 20px;
}

.related-cont {
	@extend .page-content;

	.side-left {

		h2 {
			@extend .title--small;
			margin-bottom: 2.4em;
		}

	}


	.side-right {
		position: relative;

		.go-back {
			position: absolute;
			top: 15px;
		}

		.side-right__inner-spacing {
			padding: 0px 0px 20px 0px;
		}

		@include breakpoint(large) {

			.go-back {
				top: 0;
			}

		}

		@include breakpoint(medium) {
			padding-top: 200px;

			&.no-thumb {
				padding-top: 90px;
			}

			.side-right__inner-spacing {
				padding: 20px 0px 20px 86px;
			}

		}

	}

	.side-right__wrapper {
		position: relative;
	}

	.side-right {
		margin-bottom: 20px;

		.content,
		.depliant {
			opacity: 0;
			visibility: hidden;
			display: none;
			transition: all 200ms $transition-ease;

			&.active {
				display: block;
				opacity: 1;
				visibility: visible;
			}

		}

		.thumb-cont {
			margin-bottom: 20px;

			@include breakpoint(medium) {
				margin-top: -140px;
				transform: translate( -40px, 0px );
			}

		}

		h1 {
			@extend h2;
		}

		h2, h3 {
			font-size: 22px;
			color: $body-font-color;
			letter-spacing: 0.1em;
		}

		h4, h5 {
			color: $body-font-color;
			font-size: 16px;
			letter-spacing: 0.1em;
		}

	}

	.side-right__inner-content {
		position: relative;
		padding: 15px;
		z-index: 2;

		@include breakpoint(medium) {
			padding-left: 0;
			padding-right: 20px;
		}

		@include breakpoint(large) {
			padding: 0;
		}

	}

	.side-right__inner-bg {
		position: absolute;
		background-color: #FFF;
		top: 0;
		right: 0px;
		bottom: 0px;
		left: 0px;

		@include breakpoint(medium) {
			right: 15px;
			left: 12%;
		}
	}

	.related__nav {
		position: relative;
		margin-bottom: 30px;

		@include breakpoint(xlarge) {
			width: 120%;
			transform: translateX(-20%);
		}

		ul, li {
			list-style: none;
			padding: 0;
			margin: 0;
		}

		.icon-cont {
			position: absolute;
			display: block;
			width: 100%;
			left: 25px;
			max-width: 30px;
			top: 55%;
			transform: translateY(-50%);

			svg {
				max-height: 30px;
			}

			circle, line, path, polygon, rect, polyline, ellipse {
				stroke: $gray-dark !important;
			}

		}

		a {
			position: relative;
			color: $gray-dark;
			background-color: $gray-light;
			display: block;
			border-bottom: 1px solid #cccfd1;
			padding: 15px 40px 15px 90px;
			transition: all 300ms $transition-ease;
		}

		a:hover, a:active,
		.current-menu-item a {
			color: #FFF;
			background-color: $brand;

			.arrow-right {
				&:before, &:after {
					background-color: #fff;
				}
			}


			circle, line, path, polygon, rect, polyline, ellipse {
				stroke: #FFF !important;
			}

		}
	}

	.more-info-cont {
		display: block;
		text-transform: uppercase;
		letter-spacing: 0.1em;
		padding-top: 5px;

		a {
			display: inline-block;
			padding: 15px 15px 15px 0;
			transition: all 200ms;

			&:hover, &:active {
				color: $gray-dark;
			}

		}

		@include breakpoint(medium) {
			float: right;
			padding-top: 15px;
		}

		@include breakpoint(large) {
			padding-top: 22px;
		}
	}

}

.solutions-slider-cont {
	@include spacing-responsive(margin-top, 1);
	position: relative;
	overflow: hidden;
}

.solutions-slider {
	//@extend .container;

	h1 {

		@include breakpoint(medium) {
			max-width: 50%;
		}
	}

	h3 {
		max-width: 130px;
		line-height: 1.2rem;

		&:after {
			background-color: $gray-dark;
		}
	}

	.slick-list {
		//overflow: visible;
	}

	.slick-arrow.slick-next,
	.slick-arrow.slick-prev {
		top: 58% !important;
		z-index: 5;
	}

	.slick-arrow.slick-next {
		right: 10%;
	}

	.slick-arrow.slick-prev {
		left: 10%;
	}

	@include breakpoint(large down) {

		.slick-arrow.slick-next,
		.slick-arrow.slick-prev {
			top: auto !important;
			bottom: 0;
		}

		.slick-arrow.slick-next {
			left: auto;
			right: 30px;
		}

		.slick-arrow.slick-prev {
			left: auto;
			right: 80px;
		}

	}

	@include breakpoint(xlarge only) {

		.slick-arrow.slick-next {
			right: 20px;
		}

		.slick-arrow.slick-prev {
			left: 20px;
		}
	}

	.header-page__img-cont {
	    //max-width: 60%;
	}

}

.solutions-slider__item {
	@extend .header-page;
	padding-top: 0 !important;
	margin-left: auto;
	margin-right: auto;
	opacity: 0.2;
	transition: opacity 600ms 200ms;

	.headings-cont {
		width: 100%;
	}

	.header-page__details {
		max-width: 500px;
		margin-left: 0;
		margin-right: 0;
		margin-top: 1rem;

		@include breakpoint(medium) {
			min-width: 450px;
		}

		@include breakpoint(large) {
			max-width: 700px;
		}
	}

	.header-page__overlay-outher {
		height: 110%;
		right: 55%;
    width: 60%;

		@include breakpoint(large) {
			right: 67%;
	    width: 55%;
		}
	}

	.header-page__intro {
		position: relative;
		left: auto;
		top: auto;
		right: auto;
		bottom: auto;
		width: auto;
	}
}

.slick-current {
	.solutions-slider__item {
		opacity: 1;
		transition: opacity 600ms 600ms;
	}
}

.solutions-slider__item--light {
	.header-page__intro {
		letter-spacing: 0.1em;
	}
}

.solutions-slider__nav {
	position: absolute;
	bottom: 10%;
	left: 0;
	width: 100%;
	z-index: 10;
	text-align: center;

	.solutions-slider__nav__inner {
		@extend .container;
		position: relative;
		z-index: 2;
	}

	canvas {
		position: absolute;
		left: 0;
		top: 0;
	}

	.item {
		position: relative;
		display: inline-block;
		font-size: 10px;
		letter-spacing: 0.2em;
		text-transform: uppercase;
		cursor: hand;
		cursor: pointer;
		color: $gray-dark;
		padding-top: 60px;
		margin: 0px 20px;

		.dot {
			position: absolute;
			top: 10px;
			left: 50%;
			width: 12px;
			height: 12px;
			margin-left: -6px;
			margin-top: -6px;

			div {
				position: absolute;
				top: 50%;
				left: 50%;
				width: 0px;
				height: 0px;
				border-radius: 50%;
				transform: translate(-50%, -50%);
				background-color: $brand;
				transition: all 400ms;
			}

		}

		&:nth-child(odd) {
			.dot {
				top: 40px;
			}
		}

		&.semiactive {
			.dot {

				div {
					width: 6px;
					height: 6px;
				}
			}
		}

		&.active {
			.dot {

				div {
					width: 12px;
					height: 12px;
				}
			}
		}

	}

	@include breakpoint(medium) {

		.item {
			margin: 0px 20px;
			margin: 0px 8vw;
		}

	}

	@include breakpoint(large) {

		.item {
			margin: 0px 60px;
		}

	}

}


.template-page--your-suite {

	.blocks__block--title-big .title {
		font-size: 36px;
		line-height: 1.2em;
	    margin-top: 0.6em;
	    margin-bottom: 0.4em;
	}

	.blocks__block .subtitle {
		font-size: 16px;
		max-height: 100px;
		overflow: hidden;
		position: relative;

		&:after {
			position: absolute;
			content: "";
			bottom: 0;
			left: 0;
			width: 100%;
			height: 30px;

			background: -moz-linear-gradient(top, rgba(84,94,103,0) 0%, rgba(84,94,103,0.65) 100%); /* FF3.6-15 */
			background: -webkit-linear-gradient(top, rgba(84,94,103,0) 0%,rgba(84,94,103,0.65) 100%); /* Chrome10-25,Safari5.1-6 */
			background: linear-gradient(to bottom, rgba(84,94,103,0) 0%,rgba(84,94,103,0.65) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00545e67', endColorstr='#a6545e67',GradientType=0 ); /* IE6-9 */
		}

	}

}

.content-wrapper {
	position: relative;
	overflow: hidden;

	&.is-loading {
		height: 0;
	}

}

.solutionExploreButton {
	transition: all 400ms;
	min-width: 140px;
}

@keyframes Gradient {
	0% {
		background-position: 0% 50%
	}
	50% {
		background-position: 100% 50%
	}
	100% {
		background-position: 0% 50%
	}
}

.solution-is-loading {
	.slick-current {
		.solutionExploreButton {
			cursor: default;
			background: linear-gradient(-45deg, $brand, lighten($brand, 20%), $brand, lighten($brand, 20%));
			background-size: 600% 100%;
			animation: Gradient 2s ease infinite;
		}
	}
}
