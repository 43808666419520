
.icon-arrow-down--red {
	position: absolute;
	bottom: 5%;
	left: 50%;
	z-index: 10;
	transform: translateX(-50%);
}

.num {
	@include hide-for-only(small);
	position: absolute;
	font-size: 13px;
	color: rgba(255,255,255, 0.6);
	opacity: 0;
	color: $brand;
	font-style: italic;
	letter-spacing: 0.1em;

	transform: translatey(0px);
	animation: float 6s ease-in-out infinite;
}

.num-1 { animation-delay: 500ms; }
.num-2 { animation-delay: 1000ms; }
.num-3 { animation-delay: 1500ms; }
.num-4 { animation-delay: 2000ms; }
.num-5 { animation-delay: 4000ms; }
.num-6 { animation-delay: 4500ms; }
.num-7 { animation-delay: 5400ms; }
.num-8 { animation-delay: 1200ms; }
.num-9 { animation-delay: 6000ms; }

.num-depth-2 { font-size: 11px; }
.num-depth-3 { font-size: 10px; }
.num-depth-4 { font-size: 9px; }
.num-depth-5 { font-size: 8px; }

@keyframes float {
	0% {
		transform: translatey(0px);
		opacity: 0;
	}
	50% {
		transform: translatey(-20px);
		opacity: 0.8;
	}
	100% {
		transform: translatey(0px);
		opacity: 0;
	}
}

.section__home-intro {
	position: relative;
	height: 100vh;

	.quad {
		position: absolute;
		padding: 30px 20px 20px;
		padding: 20vh 10px 5vh 20px;
		top: 30%;
		left: 15px;
		//max-width: 680px;
		z-index: 5;
		box-shadow: none;

		@include breakpoint(medium) {
			padding: 30px 20px 20px;
			padding: 30vh 10px 10vh 20px;
			top: 20%;
		}

		&:after {
			position: absolute;
			width: 80%;
			top: 0;
			left: 0;
			bottom: 0;
			content: "";
			display: block;
			background: rgba($gray-light, 0.7);
			@include shadow();
			z-index: -1;
		}

		h1 {
			position: relative;
			text-transform: uppercase;
			font-size: 125px;
			font-size: 10vw;
			letter-spacing: 0.1em;
			margin-bottom: 0px;
			line-height: 1em;
			color: $gray-dark;
		}

		h3 {
			position: relative;
			background-color: $brand;
			font-size: 12px;
			padding: 5px 10px 4px 10px;
			display: inline-block;
			text-transform: uppercase;
			letter-spacing: 0.1em;
			line-height: 0.8em;
			margin-left: 0px;
			color: #FFF;

			&:after {
				content: "";
				width: 0px;
				height: 1px;
				background-color: $gray-dark;
				position: absolute;
				top: 50%;
				left: 0px;
			}

		}

		p {
			font-size: 16px;
			margin-bottom: 0px;
			color: $gray-dark;
		}

		.icon-arrow-down--red {
			position: absolute;
			bottom: -20%;
			left: 50%;
		}

	}

	.arrow-down {
		position: absolute;
		left: 50%;
		bottom: 10px;
		transform: translateX(-50%);
	}

	@include breakpoint(large) {

		.quad {
			padding: 150px 40px 40px 80px;
			padding: 30vh 40px 10vh 80px;
			top: 20%;
			left: 10%;
			right: auto;

			h1 {
				font-size: 125px;
				font-size: 8vw;
			}

			h3 {
				font-size: 14px;
				margin-left: 135px;
				padding: 5px 20px;

				&:after {
					width: 135px;
					left: -145px;
				}
			}

			p {
				font-size: 27px;
				letter-spacing: 0.1em;
			}
		}
	}
}

.news-box {
	position: absolute;
	right: 0;
	@extend .bottom-limit;
	background-color: $gray-dark;
	color: #FFF;
	padding-top: 20px;
	padding-bottom: 20px;
	@extend .pright-limit;
	padding-left: 30px;
	z-index: 10;

	.path {
		@include hide-for-only(small);
		position: absolute;
		width: 159px;
		height: 42px;
		top: -34px;
		left: 0;
		background: url('../../images/path-gray@2x.png') center center no-repeat;
		background-size: 100% auto;
	}

	p {
		font-size: 14px;
		letter-spacing: 0.05em;
		line-height: 1.2em;
	}

	.button {
		margin-bottom: 0;
	}

	@include breakpoint(medium down) {
		bottom: 100px;
		padding-left: 20px;
	}

	@include breakpoint(medium) {

		p {
			font-size: 20px;
		}

	}

}

.section__throw {
}

.section__throw-inner {
	padding-top: 10%;
	padding-bottom: 25%;

	@include breakpoint(medium) {
		position: relative;
		display: flex;
		justify-content: center;
	}

}

.quad--sectors {

	@include breakpoint(medium) {
		transform: translateX(7%);
	}

}

.quad--references {
	position: relative;
	z-index: 2;

	@include breakpoint(medium) {
		transform: translate(-7%, 60%);
	}

}

canvas {
	opacity: 0.99;
}


.home-slider__header {
	margin-bottom: 20px;
	padding-left: 10px;
	padding-right: 10px;

	@include breakpoint(medium) {
		padding-left: 1.8rem;
		padding-right: 1.8rem;
	}

	@include breakpoint(large) {
		padding-left: 2rem;
		padding-right: 2rem;
		margin-bottom: 30px;
	}

	h3 {
		@extend .title--small;
		color: $text-color;
	}

}

.home-slider__cont--events,
.home-slider__cont--news {
	position: relative;
	@include flex-grid-column(12);
	padding-left: 0 !important;
	padding-right: 0 !important;

	.slick-arrow {

		&.slick-prev {
			top: -30px !important;
			right: 100px;
			left: auto;
		}

		&.slick-next {
			top: -30px !important;
			right: 60px;
			left: auto;
		}

	}


	@include breakpoint(medium) {
		@include flex-grid-column(6);
		padding-left: 0 !important;
		padding-right: 0 !important;

		.slick-arrow {
			display: inline-block !important;

			&.slick-prev {
				top: -35px !important;
				right: 4.5rem;
			}

			&.slick-next {
				top: -35px !important;
				right: 2rem;
				left: auto;
			}

		}
	}

	@include breakpoint(large) {

		.slick-arrow {

			&.slick-prev {
				top: -40px !important;
			}

			&.slick-next {
				top: -40px !important;
			}

		}
	}

}

.home-slider__cont--news {

	@include breakpoint(medium) {
		margin-top: 4rem;
	}

}

.arrow-down--long {
	width: 21px;

	svg {
		width: 21px;
		transition: all 200ms;
	}

	&:hover, &:active {
		svg {
			transform: translateY(10px);
		}
	}

	@include breakpoint(medium) {
		left: 30%;
	}
}

.arrow-down--center {
	margin-left: auto;
	margin-right: auto;
	display: block;
}


#section-sectors-references {
	position: relative;


}

#home-news {
	@include section-spacing-t(0.5);
	@include section-spacing-b(0.5);
}
