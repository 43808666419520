
.references-cont {
	background-color: #FFF;
	padding-top: 20px;
	padding-bottom: 20px;
	
	h4 {
		@extend .title--small;
		color: $text-color;
	}

}

.references-slide {
	max-width: 100rem;
	margin-top: 40px;
    margin-left: auto;
    margin-right: auto;

	.item {
		text-align: center;

		img {
			@extend .img-responsive;
			margin-bottom: 20px;
			margin-left: auto;
			margin-right: auto;
			//max-width: 140px;
		}

		@include breakpoint(medium) {
			//@include flex-grid-column(4);
		}

		@include breakpoint(large) {
			//@include flex-grid-column();
		}

	}
}

footer {
	font-size: 12px;
	padding-top: 20px;
	padding-bottom: 20px;
	line-height: 1.6em;

	&.footer--white {
		background-color: #FFF;
	}

}


