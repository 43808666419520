
/********** NAV **********/

#menu__overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: $gray-dark;
	z-index: 1000;
}

#menu__cont {
	position: fixed;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 1200;

	@include breakpoint(medium) {
	}

}

#nav-main {
	display: inline-block;
	list-style: none;
	margin: 0 0 30px 0;
	padding: 0 10px;

	li {
		list-style: none;
		padding: 0;
		margin: 0;
	}

	li {
		position: relative;
		font-size: 30px;
		font-size: 6vh;
		letter-spacing: 0.1em;
		text-transform: uppercase;
		@extend .font-bold;
		line-height: 1.2em;
		display: block;
	}

	.sub-menu {
		margin: 0px;
		padding: 0px;

		li {
			font-size: 12px;
			display: block;
			min-width: 200px;
		}

		a {
			color: $gray-light;
			transition: all 200ms;
		}

	}

	> li > a {
		position: relative;
		color: $gray-light;
		transition: all 200ms;

		&:before,
		&:after {
			content: "";
			position: absolute;
			bottom: 4%;
			left: 0;
			margin-left: -20px;
			width: 40px;
			height: 1px;
			background-color: #FFF;
			transition: all 200ms;
		}

		&:after {
			background-color: $brand;
			width: 0;
		}

	}

	.current-menu-item > a,
	> li:hover > a, > li:active > a,
	a:hover, a:active,
	.current-menu-parent > a,
	.current-menu-ancestor > a,
	.current-soluzioni-ancestor a {
		color: #FFF;

		&:after {
			width: 40px;
		}

	}

	@include breakpoint(small only) {

		.sub-menu {
			padding-left: 20px;
			max-height: 0px;
			overflow: hidden;
			transition: all 400ms ease-in-out;

			li {
				padding-top: 10px;

				&:last-of-type {
					padding-bottom: 10px;
				}
			}

		}

		li:hover, li:active,
		.current-menu-parent {
			.sub-menu {
				max-height: 100px;
			}
		}

	}

	@include breakpoint(medium) {
		margin-bottom: 50px;
		position: relative;

		/*
		&:after {
			position: absolute;
			top: 20%;
			left: 120%;
			content: "";
			width: 126px;
			height: 20px;
			background: url('../../images/path-header@2x.png') left center no-repeat;
			background-size: 100% auto;
		}
		*/

		li {
			font-size: 80px;
			font-size: 10vh;
			letter-spacing: 0.2em;
		}


		.menu-item-has-children > span {
			position: absolute;
			width: 16px;
			height: 26px;
			left: 100%;
			top: 50%;
			margin-top: -13px;
			opacity: 0;
			visibility: hidden;
			background: url('../../images/arrow-menu@2x.png') left center no-repeat;
			background-size: 100% auto;
			transition: all 200ms 0ms ease-in-out;
		}


		.sub-menu {
			position: absolute;
			left: 98%;
			top: 50%;
			margin: 0px;
			padding: 0px;
			padding-left: 50px;
			opacity: 0;
			visibility: hidden;
			transform: translateY(-50%);
			margin-top: -2px;
			transition: all 400ms $transition-ease;

			li {
				font-size: 12px;
				line-height: 1.4em;
				min-width: 120px;
			}

		}

		a {
			line-height: 1em;

			&:before,
			&:after {
				margin-left: -30px;
				width: 65px;
			}

			&:after {
				width: 0;
			}

		}

		.current-menu-item a,
		a:hover, a:active {

			&:after {
				width: 65px;
			}
		}

	}

	@include breakpoint(large) {
		margin-bottom: 100px;

		.sub-menu {

			li {
				font-size: 16px;
				display: block;
				min-width: 200px;
				line-height: 1.2em;
			}

		}

	}

}


.menuIsOpen {

	#nav-main {

		@include breakpoint(medium) {

			li.menu-item-has-children:hover, li.menu-item-has-children:active,
			.current-menu-parent {

				> span {
					margin-left: 10px;
					opacity: 1;
					visibility: visible;
					transition: all 200ms 0ms $transition-ease;
				}

				.sub-menu {
					opacity: 1;
					visibility: visible;
					left: 100%;
					transition: all 200ms 200ms $transition-ease;
				}

			}

		}
	}

}

#nav-secondary {
	display: block;
	margin: 0;
	padding: 0 10px;

	li {
		list-style: none;
		padding: 0;
		margin: 0;
	}

	li {
		font-size: 16px;
		display: block;
		text-transform: uppercase;
		@extend .font-bold;
		margin-right: 5px;

	}

	a {
		color: #FFFFFF;
		transition: all 200ms;

	}

	.current-menu-item a,
	a:hover, a:active {
		color: $brand;
	}


	@include breakpoint(medium) {

		li {
			display: inline-block;
			font-size: 16px;
			font-size: 1.6vw;
			letter-spacing: 0.2em;
			margin-right: 40px;
			margin-right: 3vW;
		}

	}

	@include breakpoint(large) {

		li {
			font-size: 16px;
		}

	}

}

.menu__quad {
	position: fixed;
	background-color: $gray-dark;
	z-index: 1100;

	&.menu__quad--1 {
		width: 50%;
		height: 70%;
		left: 10%;
		top: 10%;
		box-shadow: 0px 0px 15px rgba(0,0,0, 0.2);
	}

	&.menu__quad--2 {
		box-shadow: 0px 0px 20px rgba(0,0,0, 0.2);
		width: 40%;
		height: 60%;
		left: 50%;
		top: 30%;
		z-index: 1110;
	}

	@include breakpoint(small only) {

			&.menu__quad--1 {
				width: 70%;
				height: 50%;
				left: 5%;
				top: 5%;
			}

			&.menu__quad--2 {
				width: 60%;
				height: 50%;
				left: 30%;
				top: 40%;
			}

	}

}


.related__nav {
	position: relative;
	margin-bottom: 30px;

	ul, li {
		list-style: none;
		padding: 0;
		margin: 0;
	}

	a {
		position: relative;
		color: $gray-dark;
		letter-spacing: 0.12em;
		display: block;
		border-bottom: 1px solid #cccfd1;
		padding: 15px 40px 15px 30px;
		transition: all 300ms $transition-ease;
		text-decoration: none !important;
	}

	i {
		position: absolute;
		top: 50%;
		right: 20px;
		transform: translateY(-50%);
	}

	a:hover, a:active,
	.current-menu-item > a,
	.currentMenuParent > a {
		color: #FFF;
		background-color: $brand;

		.arrow-right {
			&:before, &:after {
				background-color: #fff;
			}
		}
	}

	.submenu {
		padding-left: 20px;
		display: none;

		.currentMenuParent > a,
		.current-menu-item > a {
			color: $brand;
			background-color: #FFF;
		}
	}

	.current-menu-item > .submenu,
	.currentMenuParent > .submenu {
		display: block;
	}
	
}

.archive-filters {

	a {
		text-decoration: none !important;
		font-size: rem-calc(20);
	}
}

/*
#menu__cont,
#menu__overlay,
.menu__quad {
	display: none;
}

.isLoad {
	#menu__cont,
	#menu__overlay,
	.menu__quad {
		display: block;
	}

}
*/
