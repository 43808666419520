
.template-page--single {
}

.template-page--single-products {

  .header-page {
    padding-bottom: 0 !important;
  }

  #article {
  	@extend .section-spacing-t;
  }

  .main-img__cont {
    height: 150px;
    margin-bottom: 3rem;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: left top;
    }
  }
}

.template-page--single-services {
  .slick-slide > .box {
    padding-top: 0;
  }

  .box {
    padding-bottom: 0 !important;
  }
}


.box.downloads {
  padding-top: 0;

  .row {
    border-bottom: 1px solid $gray-light-medium;
  }

  .column {
    margin-top: 1rem;
    margin-bottom: 1rem
  }

  a {
    display: block;

    &:hover, &:active {
      opacity: 0.6;
    }
  }

  i {
    display: inline-block;
    width: 30px;
    height: 20px;
    margin-top: 4px;
    background: url('../../images/icon-download@2x.png') center center no-repeat;
    background-size: contain;
  }
}
