/*
	Web fonts
*/

.font-bold {
	//font-family: $font-bold;
	font-weight: $global-weight-bold;
}

@mixin title-heavy(){
	text-transform: uppercase;
	font-size: 80px;
	font-size: 10vh;
	letter-spacing: 0.1em;
	margin-bottom: 0px;
	line-height: 1em;

	@include breakpoint(small only) {
		font-size: 40px;
	}

	@include breakpoint(large) {
		font-size: 110px;
	}
}

h1, h2, h3, h4, h5,
.h1, .h2, .h3, .h4, .h5 {
	@extend .font-bold;

	&.no-mt {
		margin-top: 0 !important;
	}

	&.no-mb {
		margin-bottom: 0 !important;
	}

	&.text--red {
		color: $brand;
	}
}

blockquote {
	font-weight: bold;

	p {
		font-size: 20px;
	}
}

article,
#home-news {
	h1, h2, h3, h4, h5,
	.h1, .h2, .h3, .h4, .h5 {
		margin-bottom: 2rem;

		&:not(.normal){
			text-transform: uppercase;
			margin-bottom: 2.4rem;
		}
	}

	h1, h2, h3, h4,
	.h1, .h2, .h3, .h4 {
		&:not(.normal){
			font-size: rem-calc(16) !important;
		}
	}

	p {
		max-width: 600px;
	}

	a:not(.button) {
		text-decoration: underline;

		&:hover, &:active {
			text-decoration: none;
		}
	}
}

.text--red {
	color: $brand;
}

.title--small {
	font-size: 16px;
	text-transform: uppercase;
	color: $gray-dark;
	letter-spacing: 0.1em;
	margin-top: 1.6em;
}
