

$quad-left-space: 0px;
$quad-left-space-medium: 50px;
$quad-left-space-large: 135px;

@mixin shadow($depth: 20px, $op: 0.2){
	box-shadow: 0px 0px $depth rgba(0,0,0, $op);
}

@mixin shadow--1(){
	@include shadow(40px);
}

@mixin shadow--2(){
	@include shadow(60px, 0.3);
}

@mixin shadow--3(){
	@include shadow(80px, 0.4);
}

.shadow {
	@include shadow();
}

.shadow--1 {
	@include shadow--1();
}

.shadow--2 {
	@include shadow--2();
}

.shadow--3 {
	@include shadow--3();
}


.quad {
	display: inline-block;
	@include shadow();
}

.quad--over-1 {
	@include shadow--1();
}


.quad--throw {
	max-width: 730px;
	display: block;
	text-align: center;
	margin: 20px 15px;

	.quad__inner {
		position: relative;
		display: inline-block;
		margin: 40px 20px 0px;
		text-align: left;
		z-index: 2;

		@include breakpoint(large) {
			margin: 40px 50px 0px;
		}

		@include breakpoint(xlarge) {
			margin: 60px 50px 20px;
		}

		@include breakpoint(xxlarge) {
			margin: 120px 50px 80px;
			margin: 5vw 50px 3vw;
		}

	}

	.title {
		text-transform: uppercase;
		color: #FFF;
		font-size: 80px;
		font-size: 6vw;
		letter-spacing: 0.1em;

		@include breakpoint(large) {
			font-size: 80px;
		}

	}


	.subtitle {
		position: relative;
		font-size: 14px;
		color: $brand;
		display: inline-block;
		text-transform: uppercase;
		letter-spacing: 0.1em;
		line-height: 0.8em;

		&:after {
			content: "";
			width: ($quad-left-space)-10;
			height: 1px;
			background-color: #FFF;
			position: absolute;
			top: 50%;
			left: -($quad-left-space);
		}

		@include breakpoint(medium) {
			margin-bottom: 50px;

			&:after {
				width: ($quad-left-space-medium)-10;
				left: -($quad-left-space-medium);
			}

		}

		@include breakpoint(large) {

			&:after {
				width: ($quad-left-space-large)-10;
				left: -($quad-left-space-large);
			}

		}

	}

	p, .button, .subtitle {
		margin-left: $quad-left-space;

		@include breakpoint(medium) {
			margin-left: $quad-left-space-medium;
		}

		@include breakpoint(large) {
			margin-left: $quad-left-space-large;
		}

	}

	p {
		color: #FFF;

		@include breakpoint(medium) {
			max-width: 200px;
		}

	}

	.overlay {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: red;
		z-index: 1;

		background: -moz-linear-gradient(left, rgba(84,94,103,1) 0%, rgba(84,94,103,0.4) 100%);
		background: -webkit-linear-gradient(left, rgba(84,94,103,1) 0%,rgba(84,94,103,0.4) 100%);
		background: linear-gradient(to right, rgba(84,94,103,1) 0%,rgba(84,94,103,0.4) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#545e67', endColorstr='#04545e67',GradientType=1 );
	}

	@include breakpoint(medium) {
		width: 50%;
		margin-bottom: 0px;
	}

}

.quad--sectors {
	background: right center no-repeat;
	background-size: cover;
}

.quad--references {
	@extend .quad--over-1;
	background: left center no-repeat;
	background-size: cover;

	.overlay {
		background: -moz-linear-gradient(left, rgba(84,94,103,0.4) 0%, rgba(84,94,103,1) 100%);
		background: -webkit-linear-gradient(left, rgba(84,94,103,0.4) 0%,rgba(84,94,103,1) 100%);
		background: linear-gradient(to right, rgba(84,94,103,0.4) 0%,rgba(84,94,103,1) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#04545e67', endColorstr='#545e67',GradientType=1 );
	}

}




.blocks {
	@include flex-grid-row(nest);
	@extend .section-spacing-b;
}

.blocks__block {
	@include flex-grid-column(12);
	margin-top: 4rem;
	letter-spacing: 0.1em;

	.date {
		margin-top: 2em;
		color: $brand;
	}

	.title {
		font-size: 20px;
		color: #FFF;
		line-height: 1.2em;
		margin-top: 0.8em;
		margin-bottom: 0.8em;
	}

	.subtitle {
		color: $gray-light-medium;
		font-weight: 300;
		font-size: 16px;
	}

	.link-label {
		margin-top: 1.4em;
		color: #FFF;
		text-transform: uppercase;
		font-size: 13px;
		letter-spacing: 0.1em;
		font-weight: 300;
	}

	.blocks__block-img-cont {
		position: relative;
		padding-bottom: 60%;
		overflow: hidden;

		img {
			width: 100%;
			height: 100%;
			position: absolute;
			object-fit: cover;
			object-position: center center;
		}
	}

	.blocks__block-max-w {
		max-width: 380px;
	}

	&:not( .blocks__block--no-link ) {

		.blocks__block-inner-wrap:hover,
		.blocks__block-inner-wrap:active {
			@extend .shadow--2;

			.date {
				color: $brand;
			}

			.blocks__block-img-cont {
				transform: scale(1.1);
				transition: all 400ms $transition-ease;
			}
		}

	}

	@include breakpoint(medium) {
		@include flex-grid-column(6);
	}

	@include breakpoint(large) {
		@include flex-grid-column(4);

		.title {
			font-size: 22px;
			line-height: 1.4em;
			margin-top: 1em;
			margin-bottom: 0.4em;
		}

		.subtitle {
			font-size: 19px;
		}

		.link-label {
			margin-top: 2.2em;
		}

	}

}

.blocks__block--no-link {
	.link-label {
		display: none !important;
	}
}

.blocks__block--slide-panel {
	@include flex-grid-column(12);
	margin-top: 0px !important;
}

.blocks__block-inner {
	position: relative;
	display: block;
}

.blocks__block-inner-wrap {
	position: relative;
	display: block;
	overflow: hidden;
	@extend .shadow;
	transition: box-shadow 400ms;
}


.blocks__block-content {
	background-color: $gray-dark;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 5;
}

.blocks__block-spacer {
	padding: 2rem;

	@include breakpoint(large) {
		padding: 3rem;
	}

}

.blocks__block-overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: $gray-dark;
	opacity: 0.68;
	z-index: 2;
}

.blocks__block--red {

	.blocks__block-overlay {
		background-color: $brand;
	}

}

.blocks__block--lighter {

	.blocks__block-content {
		background-color: $gray-light;
	}

	.blocks__block-overlay {
		display: none;
	}

	.title {
		color: $body-font-color;
	}

	.subtitle {
		color: $gray-medium;
	}

}

.blocks__block--dark {

	.blocks__block-overlay {
		display: none;
	}

	.link-label {
		color: #FFF !important;
	}

	.title {
		color: #FFF !important;
	}

	.subtitle {
		color: $gray-medium !important;
	}

}

.blocks__block--semidark {

	.blocks__block-content {
		background-color: #656e76;
	}

	.blocks__block-overlay {
		display: none;
	}

	.title {
		color: #FFF;
	}

	.subtitle {
		color: $gray-light-medium;
	}

}


.blocks__block--date-under {

	.date {
		display: inline-block;
		margin: 1em 10px 0 0;
		color: $gray-dark !important;

		@include breakpoint(large) {
			margin-top: 1.6em;
		}

	}

}

.blocks__block--link-right {

	.link-label {
		float: right;
		display: inline-block;
		color: $brand;
	}

}

.blocks__block--title-big {
	.title {
		font-size: 30px;
		color: $gray-dark;

		span {
			font-size: 30px;
		}
	}
}

.blocks__block--title-uppercase {

	.title {
		text-transform: uppercase;
	}

}

.blocks__block--title-w {

	.title {
		color: #FFF;
	}

}

.blocks__block--link-w {

	.link-label {
		color: #FFF;
	}

}

.blocks__block--full {

	.title {
		text-transform: uppercase;
		font-size: 53px;
		font-size: 10vw;
		letter-spacing: 0.1em;
		line-height: 1em;
		margin-top: 0.8em;
		margin-bottom: 0.2em;
	}

	.subtitle {
		color: #FFF;
		font-weight: 700;
		font-size: 16px;
	}

	.link-label {
		position: absolute;
		bottom: 30px;
		left: 20px;
		margin-top: 0em;
	}

	.img-real-cont {
		height: 100%;
	}

	.blocks__block-content {
		top: 30px;
		background-color: transparent;
	}

	.blocks__block-max-w {
		max-width: 1000px;
	}

	@include breakpoint(medium) {

		.title {
			font-size: 40px;
		}

	}

	@include breakpoint(large) {

		.title {
			font-size: 53px;
		}

		.subtitle {
			font-size: 22px;
		}

		.link-label {
			left: 40px;
		}

	}

}

.blocks__block--no-shadow {

	.blocks__block-inner-wrap {
		box-shadow: none !important;
	}

}


.related-references-slide {
	padding-left: 5px !important;
	padding-right: 5px !important;

	.related-references-slide__inner {
	}

	.item {
		background-color: $gray-dark;
		position: relative;
		overflow: hidden;
		margin-bottom: 40px;
		margin-left: 5px;
		margin-right: 5px;


		.title {
			font-size: 23px;
			letter-spacing: 0.1em;
			margin-top: 1.2em;
			min-height: 2.8em;
		}

		.subtitle {
			font-size: 16px;
			color: #a9aeb3;
			letter-spacing: 0.1em;
			font-weight: 300;
		}

		.item__block-spacer {
			margin-bottom: 15px;
			margin-left: 20px;
			margin-right: 20px;
			right: 0px;

			@include breakpoint(large) {
				margin-bottom: 30px;
				margin-left: 40px;
				margin-right: 30px;
			}

		}

		.item__block-content {
			position: absolute;
			background-color: $gray-dark;
			bottom: 0;
			left: 0;
			right: 0;
			z-index: 5;
		}

		.img-blank {
			@extend .img-responsive;
			margin: 0;
			padding: 0;
		}

		.img-real-cont {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 50%;
			z-index: 1;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center center;
			transition: all 200ms $transition-ease;
		}

		.cat {
			letter-spacing: 0.1em;
			padding: 16px 10px 14px;
			line-height: 1em;
			background-color: $brand;
			color: #FFF;
			text-transform: uppercase;
			text-align: center;
		}

		.item__block-inner:hover,
		.item__block-inner:active {

			.img-real-cont {
				transform: scale(1.1);
				transition: all 400ms $transition-ease;
			}

		}

	}

}

.blocks__block.elem-to-show {

	.blocks__block-overlay-for-ani {
		position: absolute;
		top: 100%;
		left: 0;
		right: 0;
		bottom: 0;
		transition: all 400ms 200ms;
		background-color: darken($gray-light, 5%);
		z-index: 10;

		//animation: overlayopen 2s ease-in-out;
		//animation-play-state: paused;
	}

	&.blocks__block--dark {
		.blocks__block-overlay-for-ani {
			background-color: darken($gray-dark, 5%);
		}
	}

	.blocks__block-inner-wrap {
		opacity: 0;
	}


}


.blocks__block.elem-to-show.elem-to-show--show {

	.blocks__block-overlay-for-ani {
		top: 0;
		bottom: 100%;
		animation: overlayopen 2s ease-in-out running;
	}

	.blocks__block-inner-wrap {
		opacity: 1;
		animation: blockopen 2s ease-in-out running;
	}

}

@keyframes overlayopen {
	0% {
		top: 100%;
		bottom: 0;
	}
	20% {
		top: 0%;
		bottom: 0;
	}
	80% {
		top: 0%;
		bottom: 0;
	}
	100% {
		top: 0;
		bottom: 100%;
	}
}

@keyframes blockopen {
	0% {
		opacity: 0;
	}
	40% {
		opacity: 0;
	}
	80% {
		opacity: 1;
	}
	100% {
		opacity: 1;
	}
}
